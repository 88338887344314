@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

:root {
  --text-color: black;
  --text-color-light: rgb(56 55 55);
  --main-font: "Poppins", arial;
  --secondary-font: "Montserrat", arial;
  --third-font: "Open Sans", arial;
  --main-color: #27ae60;
  --secondary-color: #f2f3f8;
  --white-color: white;
  --button-color: #17aa56;
  --button-color-2: linear-gradient(to right, #ff5f6d, #ffc371);
  --silver: #e2e7ec;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  font-family: var(--secondary-font);
}

*:focus:not(:focus-visible) {
  outline: none;
}

*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: var(--secondary-font);
}

#app {
  width: 100%;
  height: 100%;
  background: var(--secondary-color);
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--main-font) !important;
  font-weight: 600;
}
img {
  width: 100%;
}
button {
  background: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
}
button:hover {
  cursor: pointer;
}
label {
  user-select: none;
  cursor: pointer;
}
.user-selection-disabled {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.nolink {
  text-decoration: none;
}

.smoothed {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.respect-overflow {
  position: relative;
}

.overflow-wrap {
  overflow: hidden;
  height: 100%;
  overflow-y: auto;
}

.d-none {
  display: none;
}

ul {
  list-style: square;
  list-style-position: inside;
  line-height: 2;
  padding: 10px 0;
}

ul a {
  text-decoration: none;
}
ul a:hover {
  text-decoration: underline;
}
p {
  line-height: 1.7;
  font-size: 1rem;
}
a {
  color: var(--main-color);
}
input {
  padding: 1rem;
  background-color: #f6f7f9;
  border: none;
  border-radius: 5px;
  outline: none;
}
/* scrollbar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: #c5cad5;
  /* border-radius: 10px; */
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
  /* box-shadow: inset 7px 10px 12px #e2dfdf; */
}
@media screen and (max-width: 720px) {
  html {
    font-size: 14px;
  }
}
